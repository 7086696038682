import React from "react";
import loadable from "@loadable/component";
import Layout from "../components/layout";
import Seo from "../components/seo";

import BannerImg from "../images/home_banner.png";
import ReviewImg from "../images/review.png";

const Banner = loadable(() => import("../components/Banner/Banner"));
const TileBlockReview = loadable(() => import("../components/TileBlockReview/TileBlockReview"));
const FeaturedProperties = loadable(() => import("../components/FeaturedProperties/FeaturedProperties"));
const PatternBanner = loadable(() => import("../components/PatternBanner/PatternBanner"));
const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"));
const TileBlockRight = loadable(() => import("../components/TileBlockRight/TileBlockRight"));
const FeaturedSlider = loadable(() => import("../components/FeaturedSlider/FeaturedSlider"));

const IndexPage = () => (
  <Layout>
    <div className="layout-padding-top"></div>
    
    <Banner 
      bannerImg={BannerImg}
      tag={"home-page"}
    />

    <TileBlockReview 
      reviewtext={"“Imagine if the clothing company Boden diversified into property sales - you'd expect colourful scatter cushions and a chocolate labrador dozing by a fire. That's the welcoming look radiated by Mr and Mrs Clarke.”"}
      reviewauthor={"Aaron grey, seller"}
      reviewImg={ReviewImg}
    />

    <FeaturedProperties />

    <PatternBanner 
      tag = "blue"
      title = "ABOUT MIRA estate agents"
      desc= "Our team of estate agents are experienced, passionate and creative people who are well connected in their local community. They are knowledgeable about the property market and are driven to provide a boutique service and get the best deal for their clients. Customers work with one estate agent from start to finish."
    />

    <TileBlock />

    <TileBlockRight />

    <FeaturedSlider />

    <PatternBanner 
      tag = "brown"
      title = "start your journey"
      desc= "Our team of estate agents are experienced, passionate and creative people who are well connected in their local community."
    />
  </Layout>
)

export const Head = () => <Seo title="Home" />

export default IndexPage